/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/shared/Layout'
import TitleDefault from 'components/elements/TitleDefault'
import { Projects, ParseContent, Hero } from 'components/shared'

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    posts: allWordpressPost {
      edges {
        node {
          id
          acf {
            description
            gallery {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 300) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }

    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        banner {
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }

      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
  }
`

const PageTemplate = ({
  data: {
    posts,
    page: { acf, path, title, content, yoastMeta },
  },
}) => {
  return (
    <Layout headerActive="Projecten">
      <SEO yoast={{ meta: yoastMeta }} pathname={path} article />
      <Hero backgroundImg={acf.banner.image} title={acf.banner.title} />

      <div className="container py-5">
        <TitleDefault>{title}</TitleDefault>
        <ParseContent content={content} />

        <Projects posts={posts} />
      </div>
    </Layout>
  )
}

export default PageTemplate
